<template>
  <b-modal
    id="default-payment-option"
    ref="default-payment-option"
    centered
    title="Set Default Option Paremeters"
    ok-title="Apply"
    ok-only
    no-close-on-backdrop
    @hidden="resetModal"
    @show="setModalData()"
    @ok="applyChanges"
  >
    <validation-observer
      ref="applyOption"
      tag="div"
      class="w-100"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            v-if="selectedOption"
            cols="12"
          >
            <div class="mb-1">
              <span>{{ selectedOption.price_option.priceType }}</span>
              <span v-if="selectedOption.price_option.priceType == 'Percent discount'"> %{{ selectedOption.price_option.percentDiscount }}</span>
              <span v-if="selectedOption.price_option.priceType == 'Dollar discount'"> ${{ selectedOption.price_option.dollarDiscount }}</span>
            </div>

            <div class="mb-1">
              <template
                v-if="selectedOption.schedule_option.timeSchedule == 'Charge Immediately'"
              >
                <div>
                  <span>{{ selectedOption.schedule_option.timeSchedule }}</span>
                </div>
              </template>

              <template v-else>
                <div class="d-flex align-items-center justify-content-between mb-1">
                  <span>{{ selectedOption.schedule_option.timeSchedule }} {{ selectedOption.schedule_option.paymentAmountRange.from }}-{{ selectedOption.schedule_option.paymentAmountRange.to }}</span>
                  <validation-provider
                    #default="{ errors }"
                    name="Payment Amount"
                    rules="required"
                    class="w-50"
                  >
                    <b-form-spinbutton
                      id="range"
                      v-model="paymentAmount"
                      class="w-100"
                      :min="selectedOption.schedule_option.paymentAmountRange.from"
                      :max="selectedOption.schedule_option.paymentAmountRange.to"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-1">
                  <span>Start Date</span>
                  <validation-provider
                    #default="{ errors }"
                    name="Start Date"
                    rules="required"
                    class="w-50"
                  >
                    <flat-pickr
                      v-model="startDate"
                      placeholder="Select date"
                      class="form-control"
                      :config="{
                        dateFormat: 'm/d/Y',
                        disable: [disabledDates]
                      }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div>
                  <span>End Date {{ selectedOption.schedule_option.endDate | formatDate }}</span>
                </div>
              </template>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-1">
              <span>Payment method</span>
              <validation-provider
                #default="{ errors }"
                name="Payment method"
                rules="required"
                class="w-50"
              >
                <v-select
                  v-model="selectedMethod"
                  class="w-100"
                  placeholder="Select"
                  :options="selectedOption.method"
                  :searchable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-col>

          <b-col
            v-if="errorMessage"
            sm="12"
            class="text-center text-danger"
          >
            {{ errorMessage }}
          </b-col>
        </b-row>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormSpinbutton,
  BOverlay,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import usePaymentContract from '@/views/components/application-payment-contract-v2/usePaymentContract'
import { getFormatDate, setFormatDate } from '@/helpers/dateFormater'

export default {
  components: {
    BRow,
    BCol,
    BFormSpinbutton,
    BOverlay,

    ValidationObserver,
    ValidationProvider,

    vSelect,
    flatPickr,
  },
  filters: {
    formatDate(value) {
      return getFormatDate(value)
    },
  },
  props: {
    selectedDefaultOption: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      required: true,
    },
    paymentContractId: {
      type: [String, Number],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
      paymentAmount: 1,
      startDate: null,
      selectedMethod: null,
      errorMessage: '',
      required,
    }
  },
  watch: {
    selectedOption(val) {
      if (val !== null && val.schedule_option.timeSchedule === 'Split over') {
        this.paymentAmount = val.schedule_option.paymentAmountRange.from
      }
    },
  },
  setup() {
    const { updateApplicationPaymentOptionData } = usePaymentContract()
    return { updateApplicationPaymentOptionData }
  },
  methods: {
    setModalData() {
      this.selectedOption = JSON.parse(JSON.stringify(this.selectedDefaultOption))
      if (this.selectedDefaultOption.defaults) {
        this.paymentAmount = this.selectedDefaultOption.defaults?.paymentAmount
        this.startDate = this.selectedDefaultOption.defaults?.startDate
        this.selectedMethod = this.selectedDefaultOption.defaults?.selectedMethod
      }
    },
    resetModal() {
      this.selectedOption = null
      this.paymentAmount = 1
      this.startDate = null
      this.selectedMethod = null
      this.errorMessage = ''
    },
    async applyChanges(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise((resolve, reject) => {
        this.$refs.applyOption.validate().then(success => {
          if (success) {
            const queryParams = {
              payment_option_id: this.selectedOption.id,
              user_id: this.userId,
              status: 1,
            }
            queryParams.defaults = {
              paymentAmount: this.paymentAmount,
              selectedMethod: this.selectedMethod,
            }
            if (this.startDate) {
              queryParams.defaults.startDate = setFormatDate(this.startDate)
            }

            this.updateApplicationPaymentOptionData(queryParams).then(() => {
              this.$emit('setDefaultPaymentOptionParameters')
            })
          } else {
            reject()
          }
        })
      })
    },
    disabledDates(date) {
      const yesterday = Date.now() - 24 * 60 * 60 * 1000
      const startDate = new Date(this.selectedOption.schedule_option.startDate)
      const disabledPayDate = !this.selectedOption.available_days.includes(date.getDate())
      return yesterday > date || date > startDate || disabledPayDate
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
