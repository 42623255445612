<template>

  <app-collapse>
    <app-collapse-item
      title
      :is-visible="isVisible"
    >
      <template #header>
        <h4 class="d-flex align-items-center mb-0">
          <span :class="step.results && step.results.is_hidden ? 'text-muted' : ''">
            {{ step.name }}
          </span>
          <b-badge
            v-if="step.results && step.results.step_status"
            class="ml-1"
            :variant="`light-${resolveStatusVariant(step.results.step_status)}`"
          >
            <span :class="step.results.is_hidden ? 'text-muted' : ''">
              {{ step.results.step_status }}
          </span>
          </b-badge>
          <feather-icon
            v-if="step.results && step.results.is_hidden"
            icon="EyeOffIcon"
            size="16"
            class="align-middle text-body ml-1 text-muted"
          />
          <feather-icon
            v-else
            icon="EyeIcon"
            size="16"
            class="align-middle text-body ml-1"
          />
          <feather-icon
            v-if="canUpdateApplicationStepStatus"
            size="24"
            icon="EditIcon"
            class="cursor-pointer text-primary ml-1"
            :class="step.results && step.results.is_hidden ? 'text-muted' : ''"
            @click.stop="showChangeStepStatusModal(step)"
          />
        </h4>
      </template>
      <b-card no-body>
        <ApplicationDetails
          :key="step.id"
          :step-id="step.id"
          :step="step"
          :step-results="step.results"
          :fields-list="fieldsList"
          :default-fields-list="defaultFieldsList"
          :fields-value="fieldsValue"
        />
      </b-card>
    </app-collapse-item>
  </app-collapse>

</template>

<script>
import { mapGetters } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'

import { BCard, BBadge } from 'bootstrap-vue'
import ApplicationDetails from '@/views/components/application-details/ApplicationDetails.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BBadge,

    ApplicationDetails,
  },
  mixins: [formBuilderPreparations],
  props: {
    step: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldsValue: [],
      fieldsList: [],
      defaultFieldsList: [],
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      limitSessionSelection: 'app-application-edit/getLimitSessionSelection',
      userInfo: 'app-application-edit/getUserInfo',
    }),
    canUpdateApplicationStepStatus() {
      if (this.userInfo.role.display_name === 'Staff') {
        return this.$can('update', this.permissionSubjects.StaffApplicationStatus)
          || (this.$can('approve', this.permissionSubjects.StaffApplicationStep)
            && this.$can('reject', this.permissionSubjects.StaffApplicationStep))
      }
      return this.$can('update', this.permissionSubjects.ChildApplicationStatus)
        || (this.$can('approve', this.permissionSubjects.ChildApplicationStep)
          && this.$can('reject', this.permissionSubjects.ChildApplicationStep))
    },
  },
  created() {
    this.setFormFields()
  },
  methods: {
    setFormFields() {
      this.fieldsValue = this.step.results?.application_fields

      this.defaultFieldsList = this.step.form.fields
        .map(row => {
          if (row.type === 'file_upload') {
            return {
              id: this.fieldsValue ? this.fieldsValue.filter(item => item.field_id === row.id)[0]?.id : null,
              field_id: row.id,
              value: null,
              files: this.fieldsValue ? this.fieldsValue.filter(item => item.field_id === row.id)[0]?.files : null,
              label: row.label,
              name: row.name,
              options: this.designateOptions(row.type, row.options),
              type: this.transformField(row.type),
              required: row.required,
              disabled: true,
              default: this.fieldsValue ? this.fieldsValue.filter(item => item.field_id === row.id)[0]?.field_default : null,
            }
          }
          return {
            id: this.fieldsValue ? this.fieldsValue.filter(item => item.field_id === row.id)[0]?.id : null,
            field_id: row.id,
            value: this.fieldsValue ? this.fieldsValue.filter(item => item.field_id === row.id)[0]?.result : null,
            label: row.label,
            name: row.name,
            options: this.designateOptions(row.type, row.options),
            type: this.transformField(row.type),
            required: row.required,
            disabled: true,
            default: this.fieldsValue ? this.fieldsValue.filter(item => item.field_id === row.id)[0]?.field_default : null,
            limitSessionSelection: this.limitSessionSelection,
          }
        })
      this.fieldsList = JSON.parse(JSON.stringify(this.defaultFieldsList))
    },
    resolveStatusVariant(status) {
      if (status === 'Approved') return 'success'
      if (status === 'Rejected') return 'danger'
      if (status === 'In Review') return 'info'
      return 'primary'
    },
    showChangeStepStatusModal(step) {
      this.$store.commit('app-application-edit/SET_NEW_APPLICATION_STEP_STATUS', step?.results?.step_status)
      this.$store.commit('app-application-edit/SET_UPDATED_STEP', step)

      this.$bvModal.show('change-step-status')
    },
  },
}
</script>

<style scoped>
</style>
