<template>

  <b-card>
    <!-- Loading -->
    <b-card
      v-if="isLoading"
    >
      Loading...
    </b-card>
    <b-row
      v-else
    >
      <b-col
        cols="12"
        xl="5"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <b-avatar
              ref="previewEl"
              class="mb-1"
              :src="userData.avatar"
              :text="avatarText(userData.full_name)"
              :variant="`light-${resolveUserRoleVariant(userData.role)}`"
              size="104px"
              rounded
            />
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="inputImageRenderer"
            />
            <!--/ upload button -->
          </div>

          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <b-link
                v-if="type == 'Staff'"
                class="mb-0 cursor-pointer text-primary"
                router-tag="h3"
                :to="{ name: `admin-staff-profile`, params: { id: userData.id } }"
              >
                {{ userData.full_name }}
              </b-link>
              <b-link
                v-else
                class="mb-0 cursor-pointer text-primary"
                router-tag="h3"
                :to="{ name: 'admin-student-profile', params: { id: camper.user_id } }"
              >
                {{ userData.full_name }}
              </b-link>
            </div>
          </div>
        </div>

      </b-col>

      <b-col
        cols="6"
        xl="4"
      >
        <div class="mb-1">
          Last Step Name:
          <span class="align-middle mx-50">
            <b-badge
              variant="light-secondary"
              class="align-content-center p-1"
            >
              {{ applicationLastStepName }}
            </b-badge>
          </span>
        </div>
        <div class="mb-2">
          Program: {{ programName }}
        </div>
        <div class="mb-1">
          Date: {{ applicationCreatedAt | longDate }}
        </div>
        <div class="d-inline-flex align-items-center mb-1">
          Status:
          <span class="align-middle mx-50">
            <b-badge
              variant="light-secondary"
              class="align-content-center p-1"
            >
              {{ applicationStatus }}
            </b-badge>
          </span>
          <feather-icon
            v-if="canUpdateApplicationStatus"
            size="21"
            icon="EditIcon"
            class="cursor-pointer text-primary"
            @click.stop="$bvModal.show('change-status')"
          />
        </div>
        <div class="mb-1">
          {{ sessionSemester(2) }}: {{ sessions }}
          <feather-icon
            v-if="canUpdateApplicationSession"
            size="21"
            icon="EditIcon"
            class="cursor-pointer text-primary ml-1"
            @click="$bvModal.show('edit-sessions')"
          />
        </div>
        <div class="d-inline-flex mb-1">
          Tag Names:
          <badge-tag-list
            :tag-names="tagNames"
            class="ml-50"
          />
          <feather-icon
            v-if="canEditApplicationTags"
            size="21"
            icon="EditIcon"
            class="cursor-pointer text-primary ml-1"
            @click="$bvModal.show('application-tags-edit')"
          />
        </div>
      </b-col>
      <b-col
        cols="6"
        xl="3"
      >
        <div
          v-if="['Camper', 'Student'].includes(type)"
          class="mb-1"
        >
          <span class="align-middle">Parent Profile:
            <b-link
              :to="{ name: 'admin-parent-profile', params: { id: camper.user_parent_id } }"
            >{{ camper.parent_name }}</b-link>
          </span>
        </div>
        <div class="mb-1">
          <span class="align-middle">
            Application balance: <b>{{ applicationBalance | fixAmount }}</b>
          </span>
        </div>
      </b-col>
    </b-row>

    <application-tags-edit-modal
      :tag-names="tagNames"
      :application-id="applicationId"
      @tagsUpdated="fetchApplication"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BButton,
  BFormFile,
  BBadge,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { filters } from '@core/mixins/filters'
import useUsersList from '@/views/apps/user/users-list/useUsersList'
import ApplicationTagsEditModal from '@/views/components/application-tags-edit-modal/ApplicationTagsEditModal.vue'
import BadgeTagList from '@/views/components/badge-tag-list/BadgeTagList.vue'

export default {
  name: 'UserSummaryCard',
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BButton,
    BFormFile,
    BBadge,

    ApplicationTagsEditModal,
    BadgeTagList,
  },
  directives: {
    Ripple,
  },
  filters: {
    cutString(val) {
      let string = val
      if (val) {
        if (string.length > 20) {
          string = `${string.slice(0, 17)} ...`
        }
      }
      return string
    },
  },
  mixins: [filters],
  props: {
    canUpdateApplicationStatus: {
      type: Boolean,
      default: true,
    },
    canUpdateApplicationSession: {
      type: Boolean,
      default: true,
    },
    canEditApplicationTags: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      profileFile: null,
    }
  },
  setup(props) {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { resolveUserRoleVariant } = useUsersList()

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
      // eslint-disable-next-line no-param-reassign
      props.userData.logoUploaded = base64
    })

    return {
      avatarText,
      resolveUserRoleVariant,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  computed: {
    ...mapGetters({
      userData: 'app-application-edit/getUserInfo',
      camper: 'app-application-edit/getCamper',
      applicationStatus: 'app-application-edit/getApplicationStatusName',
      applicationCreatedAt: 'app-application-edit/getApplicationCreatedAt',
      applicationLastStepName: 'app-application-edit/getApplicationLastStepName',
      programName: 'app-application-edit/getProgramName',
      type: 'app-application-edit/getType',
      isLoading: 'app-application-edit/getIsLoading',
      sessionsList: 'app-application-edit/getSessionsList',
      applicationBalance: 'app-application-edit/getApplicationBalance',
      tagNames: 'app-application-edit/getTagNames',
      applicationId: 'app-application-edit/getApplicationId',
    }),
    sessions() {
      return this.sessionsList.map(session => session.name).join(', ')
    },
  },
  methods: {
    async fetchApplication() {
      await this.$store.dispatch('app-application-edit/fetchApplication')
    },
  },
}
</script>

<style>

</style>
