export default [
  { key: 'show_details', label: '#', selected: true },
  { key: 'id', label: 'Id', selected: true },
  {
    key: 'payment_date', label: 'Date', selected: true, sortable: true,
  },
  { key: 'payer_name', label: 'Payer Name', selected: true },
  { key: 'program_name', label: 'Program Name', selected: true },
  { key: 'model_type', label: 'Type', selected: true },
  { key: 'amount', label: 'Amount', selected: true },
  { key: 'description', label: 'Description', selected: true },
  { key: 'status', label: 'Status', selected: true },
  { key: 'type', label: 'Payment Method', selected: true },
  { key: 'remaining_total', label: 'Running Balance', selected: true },
  { key: 'charge_balance', label: 'Charge Balance', selected: true },
  { key: 'ifp_balance', label: 'Running Balance (IFN)', selected: true },
  { key: 'notes', label: 'Internal Notes', selected: true },
  { key: 'check_number', label: 'Ref #', selected: true },
  {
    key: 'check_date', label: 'Check date', selected: true, sortable: true,
  },
  { key: 'actions', label: 'Actions', selected: true },
]
