import axios from '@axios'
import { computed, ref, watch } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { DEFAULT_PER_PAGE } from '@/constants/base'

export default function useApplicationTasks(ID) {
  // Use toast
  const toast = useToast()

  const refApplicationTasksTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'task_name', label: 'task', sortable: false },
    { key: 'status', label: 'status', sortable: false },
    { key: 'date', label: 'due date', sortable: false },
    { key: 'actions' },
  ]

  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)

  const dataMeta = computed(() => {
    const localItemsCount = refApplicationTasksTable.value ? refApplicationTasksTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refApplicationTasksTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchApplicationTasks = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      applicationId: ID,
    }
    store
      .dispatch('app-application-edit/fetchApplicationTasks', payloadData)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta
        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching applications' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchApplicationTask = taskID => axios
    .get(`/auth/application-tasks/${taskID}/edit`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Task',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateApplicationTask = queryParams => axios
    .post('/auth/application-tasks/update-result', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Task was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error update Application Task Data',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const uploadFile = (id, file) => axios
    .post(`/auth/file/upload/${id}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => response.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error uploading file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const removeFile = queryParams => axios
    .post('/auth/file/remove', queryParams)
    .then(response => response.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error removing file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Completed') return 'success'
    if (status === 'Overdue') return 'danger'
    return 'primary'
  }

  return {
    currentPage,
    dataMeta,
    fetchApplicationTask,
    fetchApplicationTasks,
    perPage,
    refApplicationTasksTable,
    refetchData,
    uploadFile,
    removeFile,
    resolveStatusVariant,
    tableColumns,
    totalItems,
    updateApplicationTask,
  }
}
