<template>
  <app-collapse class="mb-2">
    <app-collapse-item
      title
      :is-visible="isVisible"
    >
      <template #header>
        <h4
          class="d-flex align-items-center mb-0"
        >
          <feather-icon
            icon="ListIcon"
            size="18"
            class="mr-1"
          />
          Notes
        </h4>
      </template>
      <b-card no-body>
        <div
          v-for="comment in commentsList"
          :key="comment.id"
          class="d-flex justify-content-between align-items-start"
        >
          <div class="d-flex justify-content-between align-items-start flex-wrap">
            <b-avatar
              size="48"
              :src="comment.user.avatar"
              :text="avatarText(comment.user.full_name)"
              :variant="`light-${resolveUserRoleVariant(comment.user.role)}`"
              class="mr-75"
            />
            <div>
              <h5 class="mb-0">
                {{ comment.user.full_name }}
              </h5>
              <span class="font-small-3 text-muted">{{ comment.user.email }}</span>
              <div><p>{{ comment.data }}</p></div>
            </div>
          </div>
          <small class="font-small-3 text-muted text-nowrap">{{ formatDate(comment.created_at) }}</small>
        </div>

        <b-form
          v-if="showAddComment"
          class="mt-2"
        >
          <b-form-textarea
            v-model="newComment"
            rows="4"
            placeholder="Add Comment"
            class="mb-2"
          />
          <b-button
            variant="primary"
            @click="addComment"
          >Post Comment
          </b-button>
        </b-form>

      </b-card>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import {
  BCard, BAvatar,
  BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import Ripple from 'vue-ripple-directive'
import { avatarText, formatDate } from '@core/utils/filter'
import useUsersList from '@/views/apps/user/users-list/useUsersList'
import useUserNotes from '@/views/components/user-notes/useUserNotes'

export default {
  directives: {
    Ripple,
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BAvatar,
    BForm,
    BFormTextarea,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    applicationId: {
      type: String,
      required: false,
      default: '',
    },
    showAddComment: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      commentsList: [],
      newComment: '',
      currentUserId: JSON.parse(localStorage.getItem('userData')).id,
    }
  },
  setup() {
    const {
      postComment,
      fetchComments,
    } = useUserNotes()

    const { resolveUserRoleVariant } = useUsersList()

    return {
      postComment,
      fetchComments,
      avatarText,
      formatDate,

      resolveUserRoleVariant,
    }
  },
  async created() {
    await this.setComments()
  },
  methods: {
    async setComments() {
      const queryParams = {
        application_id: this.applicationId,
      }
      await this.fetchComments(queryParams).then(response => {
        this.commentsList = response
      })
    },
    async addComment() {
      const queryParams = {
        application_id: this.applicationId,
        user_id: this.currentUserId,
        data: this.newComment,
      }
      await this.postComment(queryParams).then(() => {
        this.newComment = ''
        this.setComments()
      })
    },
  },
}
</script>

<style>

</style>
