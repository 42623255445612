import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePaymentContract() {
  // Use toast
  const toast = useToast()

  const fetchPaymentContractTransactionsList = queryParams => axios
    .get('/auth/pay-condition-transactions', { params: queryParams })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Payment Contract transactions',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateApplicationPaymentOption = (ID, queryParams) => axios
    .put(`/auth/application-pay-conditions/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Payment Contract was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Application Payment Contract',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))
  const deleteApplicationPaymentOption = ID => axios
    .delete(`/auth/application-pay-conditions/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Application Payment Option',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateApplicationPaymentOptionData = queryParams => axios
    .post('/auth/payment-contract/option/user', queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Application Payment Option',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateApplicationPaymentContract = ({ applicationId, contractId }) => axios
    .put(`auth/applications/${applicationId}/change/${contractId}`)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Payment Contract was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Application Payment Contract',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status.toLowerCase() === 'completed') return 'success'
    if (status.toLowerCase() === 'paid') return 'success'
    if (status.toLowerCase() === 'payment failed') return 'danger'
    if (status.toLowerCase() === 'pending') return 'warning'
    if (status.toLowerCase() === 'canceled') return 'warning'
    if (status.toLowerCase() === 'refunded') return 'primary'
    return 'primary'
  }

  return {
    fetchPaymentContractTransactionsList,
    updateApplicationPaymentOption,
    deleteApplicationPaymentOption,
    updateApplicationPaymentContract,
    updateApplicationPaymentOptionData,
    resolveStatusVariant,
  }
}
