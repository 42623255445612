<template>
  <b-card>
    <template #header>
      <h4
        class="d-flex align-items-center mb-0"
      >
        Groups
      </h4>
    </template>

    <TableSpinner v-if="isLoading" />

    <b-card v-else>

      <b-badge
        v-for="group in userGroups"
        :key="group.id"
        class="mr-1"
      >
        <b-link
          :to="{ name: 'groups-detail', params: { id: group.id } }"
          :disabled="!$can('read', permissionSubjects.Group)"
        >{{ group.name }}</b-link>
      </b-badge>
      <b-button
        v-if="$can('remove' && 'add', permissionSubjects.GroupMember)"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="d-block mt-2"
        variant="primary"
        :disabled="!isApplicationAccepted"
        @click="openEditGroupsModal"
      >Add to group</b-button>

      <EditGroupsModal
        :groups-list="userGroups"
        :sessions-list="sessionsList"
        :program-id="programId"
        :application-id="applicationId"
        :user-id="userId"
        @updateGroups="updateGroups"
      />
    </b-card>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BBadge, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import EditGroupsModal from '@/views/components/edit-user-groups/edit-groups-modal/EditGroupsModal.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BBadge,
    BLink,
    BButton,

    EditGroupsModal,
    TableSpinner,
  },
  data() {
    return {
      isLoading: false,
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      groupsList: 'app-application-edit/getGroupsList',
      sessionsList: 'app-application-edit/getSessionsList',
      type: 'app-application-edit/getType',
      userId: 'app-application-edit/getUserId',
      programId: 'app-application-edit/getProgramId',
      applicationId: 'app-application-edit/getApplicationId',
      isApplicationAccepted: 'app-application-edit/getIsApplicationAccepted',
    }),
    userGroups() {
      return Object.values(this.groupsList).flat()
    },
  },
  methods: {
    openEditGroupsModal() {
      this.$bvModal.show('edit-groups')
    },
    async updateGroups() {
      this.isLoading = true
      await this.$store.dispatch('app-application-edit/fetchApplication')
      this.isLoading = false
    },
  },
}
</script>

<style>

</style>
