import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationAdd() {
  // Use toast
  const toast = useToast()

  const postComment = queryParams => axios
    .post('/auth/comment', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New comment was successfully added',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error adding comment',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchComments = queryParams => axios
    .get('/auth/comment/', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching comments',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    postComment,
    fetchComments,
  }
}
