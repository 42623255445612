<template>

  <!-- Application Tasks Card -->
  <b-card
    no-body
  >
    <template #header>
      <h4
        class="d-flex align-items-center mb-0"
      >
        Tasks
      </h4>
    </template>

    <b-table
      ref="refApplicationTasksTable"
      :items="fetchApplicationTasks"
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <!-- Loader -->
      <template #table-busy>
        <TableSpinner />
      </template>

      <!-- Column: task name -->
      <template #cell(name)="data">
        {{ data.item.task_name }}
      </template>

      <!-- Column: status -->
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
        >
          {{ data.item.status }}
        </b-badge>
      </template>

      <!-- Column: due date -->
      <template #cell(date)="data">
        {{ data.item.date }}
      </template>

      <!-- Dropdown -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          toggle-class="mx-auto p-0"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="$can('update', permissionSubjects.UserTasks)"
            v-b-modal.edit-application-task
            @click="editableApplicationTaskId = data.item.id"
          >
            <span>Edit Task</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('delete', permissionSubjects.UserTasks) && data.item.status !== 'Manual Deleted'"
            @click="showDeleteTaskConfirmation(data.item)"
          >
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <!-- Pagination -->
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-if="totalItems && perPage !== 'all'"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <application-task-edit-modal
      :application-task-id="editableApplicationTaskId"
      @updated="refetchData"
    />

    <confirmation-modal
      v-if="deletableTask"
      :toggle-modal="confirmationModalShow"
      :title="'Are you sure'"
      :message="`Are you sure you want to delete this application task for application(${deletableTask.user_name})? All related field results and PPA for this task will be lost`"
      :is-loading="isTaskDeleteLoading"
      @close-modal="confirmationModalShow = !confirmationModalShow"
      @confirm="handleDeleteTask"
    />

  </b-card>

</template>

<script>
import {
  BBadge,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import ApplicationTaskEditModal from '@/views/admin/tasks/application-task-list/ApplicationTaskEditModal.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'

import store from '@/store'
import storeModule from '@/views/admin/tasks/taskStoreModule'
import useApplicationTasks from './useApplicationTasks'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    BTable,

    TableSpinner,
    ApplicationTaskEditModal,
    ConfirmationModal,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editableApplicationTaskId: null,
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-application-tasks'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      currentPage,
      dataMeta,
      fetchApplicationTask,
      fetchApplicationTasks,
      updateApplicationTask,
      perPage,
      refApplicationTasksTable,
      refetchData,
      uploadFile,
      removeFile,
      resolveStatusVariant,
      tableColumns,
      totalItems,
    } = useApplicationTasks(root.$router.currentRoute.params.id)

    return {
      currentPage,
      dataMeta,
      fetchApplicationTask,
      fetchApplicationTasks,
      updateApplicationTask,
      perPage,
      refApplicationTasksTable,
      refetchData,
      uploadFile,
      removeFile,
      resolveStatusVariant,
      tableColumns,
      totalItems,
    }
  },
  computed: {
    deletableTask: {
      get() {
        return store.getters['app-application-tasks/getDeletableTask']
      },
      set(val) {
        store.commit('app-application-tasks/SET_DELETABLE_TASK', val)
      },
    },
    confirmationModalShow: {
      get() {
        return store.getters['app-application-tasks/getConfirmationModalShow']
      },
      set(val) {
        store.commit('app-application-tasks/SET_CONFIRMATION_MODAL_SHOW', val)
      },
    },
    isTaskDeleteLoading: {
      get() {
        return store.getters['app-application-tasks/getIsTaskDeleteLoading']
      },
      set(val) {
        store.commit('app-application-tasks/SET_IS_TASK_DELETE_LOADING', val)
      },
    },
  },
  methods: {
    async deleteApplicationTask(applicationTaskId) {
      this.isTaskDeleteLoading = true
      const queryParams = {
        application_task_id: applicationTaskId,
        status: 'Manual Deleted',
      }

      await this.updateApplicationTask(queryParams)
        .finally(() => {
          this.deletableTask = null
          this.isTaskDeleteLoading = false
          this.confirmationModalShow = false
          this.$bvModal.hide('edit-application-task')
          this.refetchData()
        })
    },
    showDeleteTaskConfirmation(task) {
      this.deletableTask = task
      this.$nextTick(() => {
        this.confirmationModalShow = true
      })
    },
    handleDeleteTask() {
      if (this.deletableTask) {
        this.deleteApplicationTask(this.deletableTask.id)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
