<template>
  <ValidationObserver
    ref="exportTransactions"
  >
    <b-modal
      id="export-transactions-modal"
      ref="export-transactions-modal"
      :title="title"
      centered
      no-close-on-backdrop
      ok-title="Export"
      ok-only
      :ok-disabled="isLoading"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      :hide-header-close="isLoading"
      @show="setModalData"
      @hidden="resetModal"
      @ok.prevent="handleExportTransactions"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <br>
        <b-form-group
          label="Available Filters"
          class="mb-2"
        >
          <b-form-group
            v-if="!filters.application_id"
            label="Search by name"
            label-for="search"
          >
            <b-form-input
              v-model="filters.userName"
            />
          </b-form-group>

          <b-form-group
            label="Per Page"
            label-for="per_page"
          >
            <v-select
              v-model="filters.perPage"
              :options="perPageOptions"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block mr-1 w-50"
            />
          </b-form-group>

          <b-form-group
            v-if="filters.perPage !== 'all'"
            label="Page"
            label-for="per_page"
          >
            <b-form-input
              v-model="filters.page"
            />
          </b-form-group>

          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="filters.status"
              :options="filterStatusOptions"
              :clearable="true"
              :reduce="option => option.label"
              :multiple="true"
              class="per-page-selector d-inline-block mr-1 w-100"
            />
          </b-form-group>

          <b-form-group
            label="Type of transactions"
            label-for="type"
          >
            <v-select
              v-model="filters.tableViewMode"
              :options="filterTypesOptions"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block mr-1 w-100"
            />
          </b-form-group>

          <b-form-group
            label="Payment Method"
            label-for="type"
          >
            <v-select
              v-model="filters.type"
              :options="preparedFilterPaymentMethodOptions"
              :clearable="true"
              :multiple="true"
              label="name"
              :reduce="option => option.name"
              :placeholder="'All Payment Methods'"
            />
          </b-form-group>

          <b-form-group
            label="Created At"
            label-for="type"
          >
            <flat-pickr
              v-model="filters.createdAt"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', mode: 'range'}"
              :placeholder="'Select Date/Time'"
            />
          </b-form-group>
        </b-form-group>

        <b-form-group
          label="Export type"
        >
          <b-form-radio
            v-model="filters.isSendByEmail"
            class="mb-1"
            name="exportType"
            :value="false"
          >
            Download file
          </b-form-radio>
          <b-form-radio
            v-model="filters.isSendByEmail"
            name="exportType"
            :value="true"
          >
            Send file by email
          </b-form-radio>
        </b-form-group>

        <div
          v-if="filters.isSendByEmail"
        >
          <hr>
          <h5
            class="text-center py-1"
          >
            This process may take a while. You will receive an email with the csv file when complete. Please enter email below.
          </h5>
          <b-form-group
            class="mb-2"
          >
            <!-- Email -->
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email to send"
                label-for="email"
              >
                <b-form-input
                  v-model="filters.selectedEmail"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </div>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormGroup,
  BFormInput, BFormRadio,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormRadio,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BModal,

    BFormInput,
    BOverlay,
    BFormGroup,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Export Transactions',
    },
    selectedParams: {
      type: Object,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
    filterStatusOptions: {
      type: Array,
      required: true,
    },
    filterPaymentMethodOptions: {
      type: Array,
      required: true,
    },
    tableViewMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      filters: {
        selectedEmail: null,
        userName: '',
        sortBy: '',
        page: '',
        perPage: 'all',
        sortDesc: true,
        status: null,
        tableViewMode: null,
        createdAt: null,
        type: null,
        isSendByEmail: false,
      },
      filterTypesOptions: [
        'All Transactions',
        'Charges',
        'Payments',
        'Unlinked',
        'CashBank',
      ],
      preparedFilterPaymentMethodOptions: [],
      required,
      email,
    }
  },
  methods: {
    setModalData() {
      this.filters.userName = this.selectedParams.userName ?? ''
      this.filters.sortBy = this.selectedParams.sortBy ?? ''
      this.filters.page = this.selectedParams.page ?? ''
      this.filters.perPage = this.selectedParams.perPage ?? 'all'
      this.filters.sortDesc = this.selectedParams.sortDesc ?? true
      this.filters.status = this.selectedParams.status ?? null
      this.filters.createdAt = this.selectedParams.createdAt ?? null
      this.filters.application_id = this.selectedParams.applicationId ?? null
      this.filters.type = this.selectedParams.type ?? null
      this.filters.tableViewMode = this.tableViewMode

      this.filters.selectedEmail = this.userData.email ?? ''

      this.filters.isSendByEmail = false

      this.preparedFilterPaymentMethodOptions = this.filterPaymentMethodOptions.reduce((arr, item) => {
        if (arr.findIndex(option => option.name === item.name) === -1) {
          arr.push({ id: item.id, name: item.name })
        }
        return arr
      }, [])
    },
    handleExportTransactions() {
      return new Promise((resolve, reject) => {
        this.$refs.exportTransactions.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('handleExport', this.filters)
          } else {
            reject()
          }
        })
      })
    },
    resetModal() {
      this.selectedEmail = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
